
   
import React from "react"
import Page from 'components/Page'
import Layout from "components/Layout"
import useStoryblok from 'utils/storyblok'
import Button from "utils/Button"
import Seo from "utils/seo"

export default function Page404({ location }) {
  const story = useStoryblok(null, location)

  let content = (
    <div className="min-h-full lg:my-32 my-24">
      <div className="max-w-4xl mx-auto px-6">
        <main className="flex flex-col justify-conter items-center text-center">
          <div className="">
            <h1 className="lg:text-[12rem] md:text-9xl sm:text-7.5xl text-7xl !leading-[0.9] text-white uppercase font-display">Page not<br/>found</h1>
            <p className="lg:mt-6 mt-4 lg:text-lg md:text-base text-sm font-light text-white">Sorry we can't find the page you were looking for. It either doesn't exist or an error has occurred. But don't worry, there's plenty more to see on our homepage.</p>
          </div>
          <div className="lg:mt-16 mt-8">
            <Button to="/" className="text-cyan hover:text-yellow">Back to homepage</Button>
          </div>
        </main>
      </div>
    </div>
  )
  if(story && story.content) content = (<Page blok={story.content} />)

  return (
    <>
      <Seo pageName={"404"} pageMeta={{description: 'Page not found'}}/>
      <Layout location={location}>
        { content }
      </Layout>
    </>
  )
}